import { useParams } from "react-router-dom";
import { PreventiviForm } from "./PreventiviForm";
import { PresaPortDTO, PresaPortSoggettoPreventivoDTO, PreventivoPortDTO } from "../Model/PreseModel";
import { useMemo, useState } from "react";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import RestUrl from '../../RestUrl.json';
import { notifyError, notifySucces } from "../CustomToast/customToast";
import React from "react";
import { Alert, Button, Modal, Radio, RadioChangeEvent, Result } from "antd";
import { TranslateString } from "../../i18n/translate";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { TFestivi } from "../Model/Festivi";
import { PreventiviSpedizioniereUpload } from "./PreventiviSpedizioniereUpload";
import { PreventiviHeadSummary } from "./PreventiviHeadSummary";
import { checkPreseValidField, preseValidationErrorMessage, setPreseValidField, uniformaPresa } from "../Prese/PreseInterface";
import { FormateDateTimeWith00 } from "../../utils/FormatDate";
import store from "../Redux/Store";
import { ChangeUrl } from "../../utils/ChangeUrl";
import { PreventiviFormSoggetto } from "./PreventiviFormSoggetto";

interface PreventiviPageProps {

}


export const PreventiviPage: React.FC<PreventiviPageProps> = props => {
    const params: any = useParams();
    const [record, setRecord] = useState<PreventivoPortDTO | undefined>();
    const [isLogged] = React.useState(store.getState().isLogged);
    const [spedizioniereConfirmType, setSpedizioniereConfirmType] = React.useState<'UPLOAD' | 'FORM'>('UPLOAD');


    React.useEffect(
        () => {

            getRecord();
        }, []
    );

    const isPortalUserFn = (payload: any) => {
        return (payload?.tipoCliente ?? '') !== ''
    }

    const getRecord = () => {
        RestCall.getWithEasyAccess(
            RestUrl.PREVENTIVI_GET_BY_ID + params.guid,
            'url',
            (res) => {
                if (res !== undefined) {
                    res.data.presa.dataRitiro = null;

                    if (!(isPortalUserFn(res.data.presa))) {
                        res.data.presa.soggettoPreventivo = PresaPortSoggettoPreventivoDTO.getDefault();
                    }

                    setRecord(res.data)
                }
            },
            (result, error) => {

                notifyError(result)
            }
        );
    };

    const handleChanges = (presa: PresaPortDTO) => {
        if (record) {
            setRecord({
                ...record,
                presa: presa
            });
        }
    }

    const save = () => {
        if (record) {
            const presaUniformata = uniformaPresa(record.presa, true);

            const presaValidation = checkPreseValidField(setPreseValidField(presaUniformata, true, isPortalUser));
            if (presaValidation.valid) {
                const presaUniformataJSON = JSON.stringify(presaUniformata);
debugger;
                Modal.confirm({
                    title: 'Confermare i dati della presa',
                    icon: <ExclamationCircleOutlined />,
                    width: 600,
                    className: 'prese-modal',
                    onOk: () => { acceptFn() }
                });
            }
            else {
                preseValidationErrorMessage(presaValidation.message);
            }
        }
    }

    const reject = () => {
        Modal.confirm({
            title: 'Confermare l\'annullamento della richiesta',
            icon: <ExclamationCircleOutlined />,
            width: 600,
            className: 'prese-modal',
            onOk: () => { rejectFn() }
        });
    }

    const acceptFn = () => {
        if (record) {
            RestCall.postWithResultNoPaginationEasyAccess(record.presa, RestUrl.PREVENTIVI_ACCEPT_BY_ID + params.guid, 'url',
                (result: any, header) => {
                    notifySucces('Conferma avvenuta con successo')
                    ChangeUrl('');
                },
                (message) => {
                    notifyError(message);
                    //setLoading(false);
                });
        }
    }

    const rejectFn = () => {
        RestCall.postWithResultNoPaginationEasyAccess(undefined, RestUrl.PREVENTIVI_REJECT_BY_ID + params.guid, 'url',
            (result: any, header) => {
                notifySucces('Cancellazione avvenuta con successo')
                ChangeUrl('');
            },
            (message) => {
                notifyError(message);
                //setLoading(false);
            });
    }

    const onSaveSpedizioniere = (file: File) => {
        let formData = new FormData();

        formData.append("file", file, file.name);

        const HEADER_PARAMS = { "Content-Type": "multipart/form-data" };

        RestCall.postWithResultNoPaginationEasyAccess(formData, RestUrl.PREVENTIVI_ACCEPT_BY_ID_WITH_FILE + params.guid, 'url',
            (result: any, header) => {
                notifySucces('Conferma avvenuta con successo')
                //setLoading(false);
            },
            (message) => {
                notifyError(message);
                //setLoading(false);
            },
            HEADER_PARAMS
        );
    }

    const isExpired = useMemo(() => { return record?.scaduto ?? false }, [record]);

    const isSpedizioniere = useMemo(() => { return (record?.tipoCliente ?? '') === 'SPEDIZIONIERE' }, [record]);

    const isPortalUser = useMemo(() => {
        return isPortalUserFn(record);
    }, [record]);



    const inPast = useMemo(() => {
        if (record) {
            var date = new Date(record?.dataScadenza);
            return date < new Date();
        }
        return false;
    }, [record])

    const spedizioniereChangeConfirmType = (e:RadioChangeEvent)=> {
        setSpedizioniereConfirmType(e.target.value);
    }

    return <div style={{ width: '100%', height: '100%', padding: '20px 20px 0px 20px', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        {!isLogged && <div style={{ height: '50px', width: '100%', backgroundColor: 'var(--app-primaryColor)', padding: 5, display: 'flex', justifyContent: 'space-around', marginBottom: 10 }}><div className='logoIMGHeader' /> </div>}
        {record !== undefined && record.idPresa === 0 && <Alert message={`Scadenza validità preventivo: ${FormateDateTimeWith00(record?.dataScadenza ?? null)}`} type={inPast ? "error" : "success"} showIcon />}
        {record !== undefined && <PreventiviHeadSummary record={record} onChange={handleChanges} />}
        {record !== undefined && !isExpired && record.idPresa === 0 && isSpedizioniere &&
            <div style={{width: '100%',padding:10,alignItems:'center', display:'flex', flexDirection:'column'}}>
                <div>Come vuoi confermare il preventivo?</div>
                <Radio.Group defaultValue="UPLOAD" buttonStyle="solid" onChange={spedizioniereChangeConfirmType}>
                    <Radio.Button value="UPLOAD">Invia mandato di ritiro</Radio.Button>
                    <Radio.Button value="FORM">Compila il form</Radio.Button>
                </Radio.Group>
            </div>
        }
        {record !== undefined && !isExpired && record.idPresa === 0 && (isSpedizioniere && spedizioniereConfirmType === 'UPLOAD') &&
            <>
                <PreventiviSpedizioniereUpload record={record.presa} onSaveSpedizioniere={onSaveSpedizioniere} guid={params.guid} reject={reject} />
            </>
        }
        {record !== undefined && !isExpired && record.idPresa === 0 && (!isSpedizioniere || (isSpedizioniere && spedizioniereConfirmType === 'FORM')) &&
            <>
                <PreventiviForm record={record.presa} onChange={handleChanges} guid={params.guid} isPortalUser={isPortalUser} />
                <div style={{ display: 'flex', justifyContent: 'center', position: 'sticky', bottom: 0, backgroundColor: '#FFF' }}>
                    <Button size='large' style={{ marginRight: 50 }} type="primary" danger onClick={() => reject()}>{TranslateString("@PREVENTIVO_REJECT")}</Button>
                    <Button size='large' type="primary" onClick={() => save()}>{TranslateString("@PREVENTIVO_CONFIRM")}</Button>
                </div>

            </>
        }
        {record !== undefined && isExpired && record.idPresa === 0 &&
            <Result
                status="403"
                title=""
                subTitle="Il preventivo non esiste o è scaduto"
            //extra={/*<Button type="primary">Back Home</Button>*/}
            />
        }
        {record !== undefined && record.idPresa !== 0 &&
            <Result
                status="success"
                title=""
                subTitle="Il preventivo è stato confermato"
            //extra={/*<Button type="primary">Back Home</Button>*/}
            />
        }

    </div>


}


