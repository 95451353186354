import { Alert, Card, Form, Select } from "antd";
import React from "react";
import { TranslateString } from "../../i18n/translate";
import { notifyError } from "../CustomToast/customToast";
import { ProvLocCapDTO, RubricaItemModel } from "../Model/RubricaModel";
import { checkStringOnlyNumbers, FormTextInput_old, FormTimeInput } from "../Utils_Component/InputForm";
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';
import moment from "moment";
import { Moment } from "moment";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { checkOrariApertura } from "../Utils_Component/FormatDate";
import { FormSelectTime } from "../Utils_Component/FormSelect";
var _ = require('lodash');

const { Option } = Select;

interface RubricaFormProps {
    type: 'mittenti' | 'destinatari',
    record: RubricaItemModel,
    onChange: (record: RubricaItemModel) => void
}


export const RubricaForm: React.FC<RubricaFormProps> = props => {

    const [listProvince, setListProvince] = React.useState<any[]>([]);
    const [listCap, setListCap] = React.useState<any[]>([]);
    const [listLocalita, setListLocalita] = React.useState<any[]>([]);

    React.useEffect(
        () => {
            reloadListaIndirizzi();
        }, []
    );

    React.useEffect(
        () => {
            reloadListaIndirizzi();
        }, [props.record.cap, props.record.localita, props.record.provincia]
    );

    const reloadListaIndirizzi = () => {
        loadListaProvince();
        loadListaCap();
        loadListaLocalita();
    }

    const loadListaProvince = () => {
        const payload: ProvLocCapDTO = {
            provincia: null,//props.record.provincia,
            localita: props.record.localita,
            cap: props.record.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_PROVINCE_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListProvince(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaProvince();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaCap = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.provincia,
            localita: props.record.localita,
            cap: null,//props.record.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_CAPS_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListCap(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaCap();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaLocalita = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.provincia,
            localita: null,//props.record.localita,
            cap: props.record.cap
        }
        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_LOCALITA_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListLocalita(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaLocalita();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }

    const handleChangesProvincia = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.record,
            provincia: value,
            //cap: '',
            //localita: ''
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaCap(value);
        //loadListaLocalita(value, '');
    }

    const handleChangesCap = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.record,
            cap: value,
            //localita: ''
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaLocalita(props.record.provincia, value);
    }

    const handleChangesLocalita = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        //const loc = listLocalita.find((item) => item.localita === value);

        //if (loc !== undefined) {
        const newRecord = {
            ...props.record,
            localita: value,
            //cap: loc.cap

        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
        //}
    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        if (typeof name !== 'undefined') {

            let newRecord = {
                ...props.record,
                [name]: evt.target.checked
            };

            if (name === 'orarioContinuato' && evt.target.checked) {
                newRecord = {
                    ...newRecord,
                    orarioApertura2: null,
                    orarioChiusura2: null
                }
            }

            if (props.record.orarioApertura1 === '') {
                newRecord = {
                    ...newRecord,
                    orarioApertura1: null,
                }
            }

            if (props.record.orarioChiusura1 === '') {
                newRecord = {
                    ...newRecord,
                    orarioChiusura1: null,
                }
            }


            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const handleTimeMittenteChanges = (fieldName: string | undefined, time: Moment | null, timeString: string) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                [fieldName]: time !== null ? time.format('YYYY-MM-DD[T]HH:mm:ss') : ''
            };

            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const onSearchMittente = (fieldName: string | undefined, timeString: string) => {
        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                [fieldName]: timeString
            };
            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }


    const getTimeMittenteMattinoAperturaMemo = React.useMemo(() => {
        return _.isEmpty(props.record.orarioApertura1) ? undefined : moment(props.record.orarioApertura1!)
    }, [props.record.orarioApertura1]);

    const getTimeMittenteMattinoChiusuraMemo = React.useMemo(() => {
        return _.isEmpty(props.record.orarioChiusura1) ? undefined : moment(props.record.orarioChiusura1!)
    }, [props.record.orarioChiusura1]);

    const getTimeMittentePomeriggioAperturaMemo = React.useMemo(() => {
        return _.isEmpty(props.record.orarioApertura2) ? undefined : moment(props.record.orarioApertura2!)
    }, [props.record.orarioApertura2]);

    const getTimeMittentePomeriggioChiusuraMemo = React.useMemo(() => {
        return _.isEmpty(props.record.orarioChiusura2) ? undefined : moment(props.record.orarioChiusura2!)
    }, [props.record.orarioChiusura2]);

    const checkPhoneValidate = React.useMemo(() => {
        return _.isEmpty(props.record.telefono) /*|| !checkStringOnlyNumbers(props.record.telefono) rimosso il 27/01/2025 su richiesta*/ ? 'error' : undefined;
    }, [props.record.telefono]);



    return (
        <Form>

            <FormTextInput_old
                onChange={handleChanges}
                label_name="RAGSOC"
                mandatory={true}
                input_name={"ragioneSociale"}
                input_value={props.record.ragioneSociale!}
                validate={undefined}
            />

            <FormTextInput_old
                onChange={handleChanges}
                label_name="PRESSO"
                mandatory={false}
                input_name={"perContoDi"}
                input_value={props.record.perContoDi!}
                validate={undefined}
            />

            <FormTextInput_old
                onChange={handleChanges}
                label_name="INDIRIZZO"
                mandatory={true}
                input_name={"indirizzo"}
                input_value={props.record.indirizzo!}
                validate={undefined}
            />

            <div className="container-row margin-bottom-15">
                <div className="column-1D2 container-col">
                    <div className="column-1">
                        <label className="label-font" htmlFor={'filterCustomer'}>Provincia</label>
                        {<label className="label-font label-red" htmlFor={'filterCustomer'}> *</label>}
                    </div>
                    <Select
                        showSearch
                        placeholder={'Seleziona...'}
                        optionFilterProp="children"
                        onChange={handleChangesProvincia}
                        value={props.record.provincia!}
                        allowClear
                    >
                        {
                            listProvince.map(
                                (item: any) =>
                                    <Option key={'provincia_' + item} value={item}>
                                        {item}
                                    </Option>
                            )
                        }
                    </Select>
                </div>
            </div>



            <div className="container-row margin-bottom-15">
                <div className="column-1D2 container-col" style={{ marginRight: 10 }}>
                    <div className="column-1">
                        <label className="label-font" htmlFor={'filterLocalita'}>Località</label>
                        {<label className="label-font label-red" htmlFor={'filterLocalita'}> *</label>}
                    </div>
                    <Select
                        showSearch
                        placeholder={'Seleziona...'}
                        optionFilterProp="children"
                        onChange={handleChangesLocalita}
                        value={props.record.localita!}
                        allowClear
                    >
                        {
                            listLocalita.map(
                                (item: any) =>
                                    <Option key={'localita_' + item} value={item}>
                                        {item}
                                    </Option>
                            )
                        }
                    </Select>
                </div>
                <div className="column-1D5 container-col" >
                    <div className="column-1">
                        <label className="label-font" htmlFor={'filterCap'}>CAP</label>
                        {<label className="label-font label-red" htmlFor={'filterCap'}> *</label>}
                    </div>
                    <Select
                        showSearch
                        placeholder={'Seleziona...'}
                        optionFilterProp="children"
                        onChange={handleChangesCap}
                        value={props.record.cap!}
                        allowClear
                    >
                        {
                            listCap.map(
                                (item: any) =>
                                    <Option key={'cap_' + item} value={item}>
                                        {item}
                                    </Option>
                            )
                        }
                    </Select>
                </div>

            </div>

            <FormTextInput_old
                onChange={handleChanges}
                label_name="TELEFONO"
                mandatory={true}
                input_name={"telefono"}
                input_value={props.record.telefono!}
                validate={checkPhoneValidate}
                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
            />


            <div className="container-row margin-bottom-15">
                <Checkbox checked={props.record.isDefault} name="isDefault" onChange={(evt) => handleCheckChanges(evt)} >
                    Default
                </Checkbox>
            </div>
            {props.type === 'mittenti' &&
                <>
                    <Card
                        title="Apertura mattino"
                        size="small"
                        className=""
                        headStyle={{ backgroundColor: '#fafafa' }}>
                        <div className="container-row">
                            <div className="column-1D2 container-col margin-right-5">
                                {/*                                 <FormTimeInput
                                    onTimeChange={handleTimeMittenteChanges}
                                    label_name="ORA_MITTENTE_MATTINO_APERTURA"
                                    mandatory={false}
                                    input_name={"orarioApertura1"}
                                    time_value={getTimeMittenteMattinoAperturaMemo}
                                    validate={undefined}

                                />  */}
                                <FormSelectTime
                                    record={props.record}
                                    object_name={"orarioApertura1"}
                                    label_name={"ORA_MITTENTE_MATTINO_APERTURA"}
                                    validate={undefined}
                                    mandatory={true}
                                    onRecordChange={(e) => onSearchMittente('orarioApertura1', e.orarioApertura1)}
                                    da={6}
                                    a={19}
                                />
                            </div>
                            <div className="column-1D2 container-col margin-right-5">
                                {/*                                 <FormTimeInput
                                    onTimeChange={handleTimeMittenteChanges}
                                    label_name="ORA_MITTENTE_MATTINO_CHIUSURA"
                                    mandatory={false}
                                    input_name={"orarioChiusura1"}
                                    time_value={getTimeMittenteMattinoChiusuraMemo}
                                    validate={undefined}

                                /> */}
                                <FormSelectTime
                                    record={props.record}
                                    object_name={"orarioChiusura1"}
                                    label_name={"ORA_MITTENTE_MATTINO_CHIUSURA"}
                                    validate={undefined}
                                    mandatory={true}
                                    onRecordChange={(e) => onSearchMittente('orarioChiusura1', e.orarioChiusura1)}
                                    da={6}
                                    a={19}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card
                        title="Apertura pomeriggio"
                        size="small"
                        className=""
                        headStyle={{ backgroundColor: '#fafafa' }}>
                        <div className="container-row">
                            <div className="column-1D2 container-col margin-right-5">
                                {/* <FormTimeInput
                                    onTimeChange={handleTimeMittenteChanges}
                                    label_name="ORA_MITTENTE_POMERIGGIO_APERTURA"
                                    mandatory={false}
                                    input_name={"orarioApertura2"}
                                    time_value={getTimeMittentePomeriggioAperturaMemo}
                                    validate={undefined}
                                    readonly={props.record.orarioContinuato ? true : undefined}
                                /> */}
                                <FormSelectTime
                                    record={props.record}
                                    object_name={"orarioApertura2"}
                                    label_name={"ORA_MITTENTE_POMERIGGIO_APERTURA"}
                                    validate={undefined}
                                    mandatory={true}
                                    onRecordChange={(e) => onSearchMittente('orarioApertura2', e.orarioApertura2)}
                                    da={6}
                                    a={19}
                                />
                            </div>
                            <div className="column-1D2 container-col margin-right-5">
                                {/*                             <FormTimeInput
                                    onTimeChange={handleTimeMittenteChanges}
                                    label_name="ORA_MITTENTE_POMERIGGIO_CHIUSURA"
                                    mandatory={false}
                                    input_name={"orarioChiusura2"}
                                    time_value={getTimeMittentePomeriggioChiusuraMemo}
                                    validate={undefined}
                                    readonly={props.record.orarioContinuato ? true : undefined}
                                /> */}
                                <FormSelectTime
                                    record={props.record}
                                    object_name={"orarioChiusura2"}
                                    label_name={"ORA_MITTENTE_POMERIGGIO_CHIUSURA"}
                                    validate={undefined}
                                    mandatory={true}
                                    onRecordChange={(e) => onSearchMittente('orarioChiusura2', e.orarioChiusura2)}
                                    da={6}
                                    a={19}
                                />
                            </div>
                        </div>
                    </Card>
                    <div className="container-row margin-bottom-15 margin-top-15">
                        <Checkbox checked={props.record.orarioContinuato} name="orarioContinuato" onChange={(evt) => handleCheckChanges(evt)} >
                            Continuato
                        </Checkbox>
                    </div>
                    {!checkOrariApertura(props.record.orarioApertura1, props.record.orarioChiusura1, props.record.orarioApertura2, props.record.orarioChiusura2, props.record.orarioContinuato) && <Alert message={TranslateString("@ERR_INSER_ORARI")} type="warning" showIcon style={{ marginBottom: 20 }} />}
                </>
            }
        </Form>
    );
}