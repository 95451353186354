import { Button, Checkbox, Form, Select, Table } from "antd";
import React from "react";
import { TranslateString } from "../../i18n/translate";
import { notifyError } from "../CustomToast/customToast";
import { DestinazionePortDTO, getPreseImballiRowType, ImballoPortDTO, PresaPortDTO } from "../Model/PreseModel";
import { checkStringOnlyNumbers, FormNumberInput, FormTextInput_new } from "../Utils_Component/InputForm"
import { RightFixed } from "../AntDesignType";
import { EdiTable } from '../Utils_Component/EditableCell'
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import RestUrl from '../../RestUrl.json';
import {
    ReloadOutlined, StopOutlined, PlusOutlined, ApartmentOutlined, WarningOutlined, LeftOutlined, EditOutlined, DeleteOutlined, CopyOutlined
} from '@ant-design/icons';
import { ProvLocCapDTO } from "../Model/RubricaModel";
var _ = require('lodash');
const { Option } = Select;

interface PreseFormDestinazioneProps {
    destinazione: DestinazionePortDTO,
    onChange: (updated: DestinazionePortDTO) => void,
    readonly?: boolean,
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const PreseFormDestinazione: React.FC<PreseFormDestinazioneProps> = props => {

    const [listProvince, setListProvince] = React.useState<any[]>([]);
    const [listCap, setListCap] = React.useState<any[]>([]);
    const [listLocalita, setListLocalita] = React.useState<any[]>([]);
    const [listNazioni, setListNazioni] = React.useState<any[]>([]);

    const isFormReadOnly = props.readonly;

    React.useEffect(
        () => {
            loadListaNazioni();
            reloadListaIndirizzi();
        }, []
    );

    React.useEffect(
        () => {
            reloadListaIndirizzi();
        }, [props.destinazione.cap, props.destinazione.localita, props.destinazione.provincia]
    );

    const reloadListaIndirizzi = () => {
        loadListaProvince();
        loadListaCap();
        loadListaLocalita();
    }


    const loadListaNazioni = () => {
        RestCall.getAll(RestUrl.RUBRICA_NATIONS, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListNazioni(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaNazioni();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaProvince = () => {
        const payload: ProvLocCapDTO = {
            provincia: null,//props.record.provincia,
            localita: props.destinazione.localita,
            cap: props.destinazione.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_PROVINCE_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListProvince(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaProvince();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaCap = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.destinazione.provincia,
            localita: props.destinazione.localita,
            cap: null,//props.record.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_CAPS_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListCap(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaCap();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaLocalita = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.destinazione.provincia,
            localita: null,//props.record.localita,
            cap: props.destinazione.cap
        }
        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_LOCALITA_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListLocalita(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaLocalita();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }


    function handleChange(record: any, value: any) {
        record.rowType = value;
    }

    const Columns = [
        {
            title: 'tipo',
            dataIndex: '',
            key: 'tipo',
            width: 150,
            editable: false,
            render: (record: any) => {
                let defaultValue = getPreseImballiRowType(record).type;

                return (
                    <Select defaultValue={defaultValue} style={{ width: 120 }} onChange={(value) => handleChange(record, value)}>
                        <Option value="colli">Colli</Option>
                        <Option value="pallet">Pallet</Option>
                    </Select>
                );
            }
        },
        {
            title: TranslateString('@DESTINAZIONI_DESCRIZIONE'),
            dataIndex: 'descrizione',
            key: 'descrizione',
            width: 200,
            editable: !isFormReadOnly
        },
        {
            title: 'Numero',
            dataIndex: 'numberGeneric',
            key: 'numberGeneric',
            width: 80,
            editable: !isFormReadOnly
        },
        {
            title: TranslateString('@DESTINAZIONI_LUNGHEZZA'),
            dataIndex: 'lunghezza',
            key: 'lunghezza',
            editable: !isFormReadOnly
        },
        {
            title: TranslateString('@DESTINAZIONI_LARGHEZZA'),
            dataIndex: 'larghezza',
            key: 'larghezza',
            editable: !isFormReadOnly
        },
        {
            title: TranslateString('@DESTINAZIONI_ALTEZZA'),
            dataIndex: 'altezza',
            key: 'altezza',
            editable: !isFormReadOnly
        }/*,
        {
            title: TranslateString('@DESTINAZIONI_PESO'),
            dataIndex: 'peso',
            key: 'peso',
            editable: !isFormReadOnly
        }*/,
        {
            title: '',
            dataIndex: '',
            width: 45,
            key: 'extra_sagoma',
            render: (record: any) => (

                ((record.altezza > 270 || record.lunghezza > 650 || record.larghezza > 650) || (record.lunghezza > 240 && record.larghezza > 240)) ?
                    <StopOutlined style={{ color: 'red' }} title="Misure troppo grandi" /> :
                    (
                        /*  record.lunghezza > 300 ||
                            record.larghezza > 300 ||
                            (record.lunghezza > 140 && record.larghezza > 120) ||
                            (record.lunghezza > 120 && record.larghezza > 140) 
                        */
                        record.lunghezza > 120 || record.larghezza > 120 ?
                            <WarningOutlined style={{ color: 'orange' }} title={TranslateString("@TOLTIP_EXTRA_SAGOMA")} /> : ''
                    )
            )
        }
    ];

    const actions = [
        {
            title: "",
            key: 'action',
            fixed: RightFixed,
            width: "50px",
            render: (text: any, record: any) => (
                <div className="container-col">
                    <div className="container-row c-ctr">
                        <Button
                            className="red-background-ghost"
                            title={TranslateString("@DELETE")}
                            shape="round"
                            size={'small'}
                            icon={<DeleteOutlined />}
                            onClick={() => deleteRow(record)}
                            disabled={isFormReadOnly}
                        />
                    </div>
                </div>
            ),
        }
    ];

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.destinazione,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleNumberChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.destinazione,
            [name]: +value
        };
        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        if (typeof name !== 'undefined') {

            const newRecord = {
                ...props.destinazione,
                [name]: evt.target.checked
            };

            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const handleChangesProvincia = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.destinazione,
            provincia: value,
            //cap: '',
            //localita: ''
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaCap(value);
        //loadListaLocalita(value, '');
    }

    const handleChangesCap = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.destinazione,
            cap: value,
            //localita: ''
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaLocalita(props.destinazione.provincia, value);
    }

    const handleChangesLocalita = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        const newRecord = {
            ...props.destinazione,
            localita: value,
            //cap: loc.cap

        };

        props.onChange(newRecord);
    }

    const handleChangesNazioneDestinazione = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const naz = listNazioni.find((item) => item.codice === value);

        if (naz !== undefined) {
            const newRecord = {
                ...props.destinazione,
                codiceNazioneFinale: naz.codice,
                nazioneFinale: naz.descrizione
            };

            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const normalizeImballo = (value: any): any => {
        /*value.lunghezza = value.lunghezza.replace(',','.');
        value.larghezza = value.larghezza.replace(',','.');
        value.altezza = value.altezza.replace(',','.');
        value.nrPallet = value.nrPallet.replace(',','.');
        value.nrColli = value.nrColli.replace(',','.');
        value.peso = value.peso.replace(',','.');*/
        return value;
    }

    const changeEditable = (value: any[]) => {

        //const valueMapped = value.map(v => normalizeImballo(v)); 

        let volume = 0;
        for (var i = 0; i < value.length; i++) {
            const row = value[i];
            const rowL = row.lunghezza === undefined ? 0 : (+row.lunghezza);
            const rowW = row.larghezza === undefined ? 0 : (+row.larghezza);
            const rowH = row.altezza === undefined ? 0 : (+row.altezza);
            const rowQta = row.numberGeneric === undefined ? 0 : (+row.numberGeneric);
            volume += (rowQta * rowL * rowW * rowH);
        }
        volume /= 1000000;

        const newRecord = {
            ...props.destinazione,
            imballi: [...value],
            volumeTotale: volume
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const createNewRow = () => {
        const newimballi = [...props.destinazione.imballi, ImballoPortDTO.getDefault()];
        const newRecord = {
            ...props.destinazione,
            imballi: newimballi
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    };

    const deleteRow = (row: any) => {

        const newimballi = props.destinazione.imballi.filter(p => (p as any)['key'] != row['key']);
        const newRecord = {
            ...props.destinazione,
            imballi: newimballi
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const imballiMemo = React.useMemo(() => props.destinazione.imballi.map((el, index) => { return { ...el, key: index } }), [props.destinazione.imballi]);

   

    const checkPhoneValidate = React.useMemo(() => {
        return _.isEmpty(props.destinazione.telefono) /*|| !checkStringOnlyNumbers(props.destinazione.telefono) rimosso il 27/01/2025 su richiesta*/ ? 'error' : undefined;
    }, [props.destinazione.telefono]);

    return (
        <>
            <div className="container-col desktop-flex height-100">

                <Form>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="RAGSOC"
                                mandatory={true}
                                input_name={"ragioneSociale"}
                                input_value={props.destinazione.ragioneSociale!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                                onKeyPress={props.onKeyPress}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="C/O"
                                mandatory={false}
                                input_name={"perContoDi"}
                                input_value={props.destinazione.perContoDi!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="INDIRIZZO"
                                mandatory={true}
                                input_name={"indirizzo"}
                                input_value={props.destinazione.indirizzo!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row margin-bottom-15">
                        <div className="column-1D4 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Provincia</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesProvincia}
                                value={props.destinazione.provincia!}
                                allowClear
                            >
                                {
                                    listProvince.map(
                                        (item: any) =>
                                            <Option key={'provincia_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Località</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesLocalita}
                                value={props.destinazione.localita!}
                                allowClear
                            >
                                {
                                    listLocalita.map(
                                        (item: any) =>
                                            <Option key={'localita_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D4 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>CAP</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesCap}
                                value={props.destinazione.cap!}
                                allowClear
                            >
                                {
                                    listCap.map(
                                        (item: any) =>
                                            <Option key={'cap_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="TELEFONO"
                                mandatory={true}
                                input_name={"telefono"}
                                input_value={props.destinazione.telefono!}
                                validate={checkPhoneValidate}
                                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="PERSONARIFERIMENTO"
                                mandatory={false}
                                input_name={"personaRiferimento"}
                                input_value={props.destinazione.personaRiferimento!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5" style={{ alignItems: 'center' }}>
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.destinazione.flagPreavvisoTelefonico} name="flagPreavvisoTelefonico" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PREAVVISOTELEFONICO")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col margin-right-5" style={{ alignItems: 'center' }}>
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.destinazione.flagSpondaIdraulica} name="flagSpondaIdraulica" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_SPONDAIDRAULICA")}
                            </Checkbox>
                        </div>

                    </div>
                    <div className="container-row"  >
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Nazione destinazione</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesNazioneDestinazione}
                                value={props.destinazione.codiceNazioneFinale!}
                            >
                                {
                                    listNazioni.map(
                                        (item: any) =>
                                            <Option key={'nazione_' + item.codice} value={item.codice}>
                                                {item.codice} - {item.descrizione}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="DESTINAZIONE_FINALE"
                                mandatory={false}
                                input_name={"destinazioneFinale"}
                                input_value={props.destinazione.destinazioneFinale!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row" style={{ marginTop: '10px' }} >
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="NOTE_CONSEGNA"
                                mandatory={false}
                                input_name={"noteConsegna"}
                                input_value={props.destinazione.noteConsegna!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                                maxLength={256}
                            />
                        </div>
                    </div>
                </Form>
                <div className="height-100-32" style={{ marginTop: '10px' }}>
                    {isFormReadOnly ? <></> : <Button className="table-new-button" onClick={createNewRow}><PlusOutlined />{TranslateString("@NEW_ROW")}</Button>}
                    <EdiTable dataSource={imballiMemo} columns={[...Columns, ...actions]} onChangeData={isFormReadOnly ? () => { } : changeEditable} />
                </div>
                <div className="container-row" style={{ justifyContent: 'end' }}>
                    <div className="column-1D2 container-col " >
                    </div>

                    <div className="column-1D2 container-col " style={{ alignItems: 'center' }}>
                        <div className="column-1">
                            <label className="label-font" htmlFor={''}>&nbsp;</label>
                        </div>
                        <Checkbox disabled={isFormReadOnly} checked={props.destinazione.flagMerceNonSovrapponibile} name="flagMerceNonSovrapponibile" onChange={(evt) => handleCheckChanges(evt)} >
                            {TranslateString("@FLAG_MERCENONSOVRAPPONIBILE")}
                        </Checkbox>
                    </div>
                    <div className="container-col margin-right-5">
                        <FormNumberInput
                            onChange={handleNumberChanges}
                            label_name="PESO_TOTALE"
                            mandatory={true}
                            input_name={"pesoTotale"}
                            input_value={props.destinazione.pesoTotale!}
                            validate={undefined}
                            readonly={isFormReadOnly}
                        />
                    </div>
                    <div className="container-col">
                        <FormNumberInput
                            onChange={handleNumberChanges}
                            label_name="VOLUME_TOTALE"
                            mandatory={true}
                            input_name={"volumeTotale"}
                            input_value={props.destinazione.volumeTotale!}
                            validate={undefined}
                            readonly={isFormReadOnly}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}